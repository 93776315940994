module.exports = {
  "name": "printemps-rouen",
  "client": "Printemps Rouen",
  "codifName": "M432",
  "validLangs": ["en", "fr", "zh"],
  "native": {
    "author": "Mobile-Spot",
    "backgroundColor": "0xffffff",
    "iosBuildTarget": "12.0",
    "androidFadeSplashScreenDuration": 750,
    "androidTargetSdkVersion": 33,
    "androidCompileSdkVersion": 33,
    "androidSigning": {
      "keyAlias": "Printemps-Rouen",
      "keyStorePassword": "ohCee8too7raifee"
    },
    "app": "app-react",
    "appleTeamId": "2BAP3P29V2",
    "name": {
      "default": "Printemps Rouen_D"
    },
    "id": "com.printemps.rouen.dev",
    "appleAppId": "1605952143"
  },
  "cordova": true,
  "crypto": false,
  "customFileUrlScheme": "msfile",
  "geoGroup": {},
  "web": {
    "url": "rouen.ms-dev.mobile-spot.com"
  },
  "version": {
    "mainVersion": "1.0.0",
    "buildVersion": {
      "iOS": "6",
      "android": "6",
      "web": "12"
    }
  },
  "relatedRepos": [{
    "src": "git@bitbucket.org:mobilespotdev/cordova.git",
    "postCommands": ["cd cordova && npm install"],
    "branch": "inte/printemps-rouen/main"
  }, {
    "src": "git@bitbucket.org:mobilespotdev/mobigeo.git",
    "postCommands": ["cd mobigeo && npm install"],
    "branch": "inte/printemps-rouen/main"
  }, {
    "src": "git@bitbucket.org:mobilespotdev/mobigeo-customs-master.git",
    "dest": "mobigeo/mobigeo-customs",
    "branch": "inte/printemps-rouen/main"
  }, {
    "src": "git@bitbucket.org:mobilespotdev/app-react.git",
    "branch": "inte/printemps-rouen/main"
  }, {
    "src": "git@bitbucket.org:mobilespotdev/data-master.git",
    "dest": "app-react/app-customs",
    "branch": "inte/printemps-rouen/main",
    "postCommands": ["cd app-react && yarn", "cd app-react && npm run set-env dev", "cd app-react && npm run download-assets", "cd app-react && npm run download-data-files", "cd app-react && npm run update-mobigeo"]
  }]
};